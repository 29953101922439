import React from "react"
import { graphql } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import Img from "gatsby-image"
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from "mdbreact"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"

function AboutPage({ data }) {
  return (
    <Layout>
      <SEO title="About" />
      <PageHero title="About" subtitle="The developer behind the screens" />
      <MDBContainer className="my-md-5 py-md-5">
        <MDBAnimation type="fadeIn" delay="0.3s">
          <MDBRow>
            <MDBCol
              size="12"
              md="5"
              className="order-md-last pb-5 pb-md-0"
              middle
            >
              <Img fluid={data.file.childImageSharp.fluid} alt="Workspace" />
            </MDBCol>
            <MDBCol size="12" md="7" className="pb-5">
              <h2 className="h2-responsive pb-4 font-weight-bold">Hello</h2>
              <p>
                I'm Z. A self taught full-stack web developer and graphic
                designer. I take projects from ideas to completed works. My
                passion for technology keeps me in a perpetual state of motion,
                so I am always learning and experimenting with new technologies
                and platforms. Currently I am heavily focused on building high
                performing{" "}
                <OutboundLink
                  href="https://jamstack.org/"
                  target="_blank"
                  alt="About JAMstack"
                  rel="noopener noreferrer"
                >
                  JAMstack websites and applications{" "}
                </OutboundLink>
                that are lightning fast, accessible and visually appealing.
              </p>
              <p>
                When I am not behind the screen, I enjoy spending a lot of time
                in nature. You can find me out ripping trails on my mountain
                bike, hiking with my 2 dogs, or in the garage turning wood into
                sawdust.
              </p>
            </MDBCol>
          </MDBRow>
        </MDBAnimation>
      </MDBContainer>
    </Layout>
  )
}
export default AboutPage

export const query = graphql`
  query AboutQuery {
    file(relativePath: { eq: "desk.jpg" }) {
      id
      childImageSharp {
        fluid(maxWidth: 500, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
